@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&family=Poppins:wght@300;400;500;600;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body,
html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.formikLabel {
  @apply text-white mt-5 text-xs font-inter;
}

.formikInput {
  @apply px-3 py-2 outline-none text-sm w-full bg-grey_black text-white placeholder-gray-300 rounded-lg;
}

.err-msg {
  @apply text-red-500 w-full text-xs;
}
